import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { InputProps as MUI_InputProps } from '@mui/material/Input';
import { Alert, Stack } from '@mui/material';

type TInputType = 'text' | 'password' | 'email' | 'tel' | 'number';
type TInputAutocomplete = 'current-password' | 'tel' | 'email' | 'name' | 'given-name' | 'family-name' | 'off';
export interface IInputProps {
    label?: string;
    type?: TInputType;
    required?: boolean;
    autocomplete?: TInputAutocomplete;
    error?: boolean;
    helperText?: React.ReactNode;
    name?: string;
    disabled?: boolean;
    readOnly?: boolean;
    value?: any;
    maxLength?: number;
    autoFocus?: boolean;

    max?: number;
    min?: number;

    multiline?: boolean;
    rows?: number;

    startIcon?: JSX.Element;
    startIconIsButton?: boolean;
    startIconButtonClicked?: (event: React.MouseEvent) => void;

    endIcon?: JSX.Element;
    endIconIsButton?: boolean;
    endIconButtonClicked?: (event: React.MouseEvent) => void;

    enforceStrongPassword?: boolean;

    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent) => void;

    className?: string;
    size?: 'small' | 'medium';
    style?: any;
    margin?: 'dense' | 'none' | 'normal';
}
const Input: FunctionComponent<IInputProps> = (props: IInputProps) => {

    const [bufferValue, setBufferValue] = useState(props.value);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        setBufferValue(newValue as any);
        if (props.onChange) {
            props.onChange(e);
        }
    };

    const getIcon = (icon: JSX.Element) => {
        const color = props.error ? 'error' : props.disabled ? '' : (icon.props?.color ?? '');
        return React.cloneElement(icon, {...icon.props,
            color
        })
    };

    const buildIconAdornment = (icon: JSX.Element, position: 'start' | 'end') => (
        <InputAdornment position={position}>
            {getIcon(icon)}
        </InputAdornment>
    );

    const buildIconButtonAdornment = (icon: JSX.Element, clickEvent: (e: React.MouseEvent) => void, position: 'start' | 'end') => (
        <InputAdornment position={position}>
            <IconButton onClick={(e) => clickEvent(e)} edge={position} disabled={props.disabled} tabIndex={-1}>
                {getIcon(icon)}
            </IconButton>
        </InputAdornment>
    );

    const inputProps: MUI_InputProps = {
        autoComplete: props.autocomplete ?? 'none'
    };
    if (props.startIcon && props.startIconIsButton && props.startIconButtonClicked && typeof props.startIconButtonClicked === 'function') {
        inputProps.startAdornment = buildIconButtonAdornment(props.startIcon, props.startIconButtonClicked, 'start');
    } else if (props.startIcon) {
        inputProps.startAdornment = buildIconAdornment(props.startIcon, 'start');
    } 

    if (props.endIcon && props.endIconIsButton && props.endIconButtonClicked && typeof props.endIconButtonClicked === 'function') {
        inputProps.endAdornment = buildIconButtonAdornment(props.endIcon, props.endIconButtonClicked, 'end');
    } else if (props.endIcon) {
        inputProps.endAdornment = buildIconAdornment(props.endIcon, 'end');
    }

    const addInputProps = (props: any) => {
        inputProps.inputProps = {...(inputProps.inputProps ?? {}),
            ...props
        }
    }

    if (props.maxLength) {
        addInputProps({
            maxLength: props.maxLength
        });
    }

    if (props.min || props.min === 0) {
        addInputProps({
            min: props.min
        });
    }

    if (props.max) {
        addInputProps({
            max: props.max
        });
    }

    if (props.type === 'number') {
        addInputProps({
            step: 'any'
        });
    }

    if (props.readOnly) {
        addInputProps({
            readOnly: props.readOnly
        });
    }

    let helperText: React.ReactNode = props.helperText;

    if (props.enforceStrongPassword) {
        const buildPasswordHelperText = () => {
            if (!props.value) {
                return null;
            }
    
            const errors: string[] = [];
            if (!props.value.match(/[a-z]{1,}/)) {
                errors.push('1 Lowercase');
            }
            if (!props.value.match(/[A-Z]{1,}/)) {
                errors.push('1 Uppercase');
            }
            if (!props.value.match(/[0-9]{1,}/)) {
                errors.push('1 Number');
            }
            if (!props.value.match(/[~!@#$%^&*()_+=-`]{1,}/)) {
                errors.push('1 Special Character');
            }
            if (props.value.length < 6) {
                errors.push('At least 6 characters long');
            }
    
            if (errors.length < 1) {
                return null;
            }
    
            return (
                <Alert severity={props.error ? 'error' : 'info'}>
                    <Stack direction='column' spacing={0}>
                        {
                            errors.map(x => (
                                <span key={x}>{x}</span>
                            ))
                        }
                    </Stack>
                </Alert>
            )
        };

        helperText = buildPasswordHelperText();
    }

    useEffect(() => {
        if (bufferValue !== props.value) {
            setBufferValue(props.value);
        }
    }, [props.value]);

    return (
        <TextField 
            className={props.className}
            label={props.label}
            type={props.type ?? 'text'}
            InputProps={inputProps}
            required={props.required}
            error={props.error}
            helperText={helperText}
            name={props.name}
            disabled={props.disabled}
            value={bufferValue}
            onChange={handleChange}
            onKeyUp={e => props.onKeyUp ? props.onKeyUp(e) : null}
            autoFocus={props.autoFocus}
            multiline={props.multiline}
            rows={props.rows}
            size={props.size}
            style={props.style}
            margin={props.margin} />
    );
};

export default Input;